import { FormControl, InputBaseProps, MenuItem, Select as MUISelect } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Icon } from 'shared/ui/Icon';
import { InputLabel } from 'shared/ui/InputLabel';
import { SelectOption } from 'shared/ui/Select/types';
import { InputError } from 'shared/ui/InputError';

export interface SelectProps extends Omit<InputBaseProps, 'onSelect'> {
	label?: string;
	value: string | string[] | undefined;
	defaultValue?: string | string[] | undefined;
	placeholder: string;
	onSelect?: (value: string | string[] | undefined) => void;
	options: Array<SelectOption>;
	multiple?: boolean;
	errorText?: string;
	disabled?: boolean;
}

export const Select: FC<SelectProps> = ({
	sx,
	label,
	value,
	placeholder,
	onSelect,
	options,
	multiple,
	errorText,
	required,
	disabled,
	defaultValue,
}) => {
	const { t } = useTranslation();

	return (
		<FormControl
			variant={'standard'}
			fullWidth={true}
			sx={{ marginBottom: '40px', ...sx }}
			error={required}
		>
			{label && <InputLabel name={label} label={label} required={required} />}
			<MUISelect
				defaultValue={defaultValue}
				disabled={disabled}
				multiple={multiple}
				value={value}
				IconComponent={() => (
					<Icon
						id={'custom_arrow_down'}
						style={{ width: '18px', height: '9px', marginRight: '10px' }}
						color={ColorPalette.astronaut}
					/>
				)}
				displayEmpty={true}
				renderValue={(value) => {
					if (!value || !value?.length) {
						return placeholder;
					} else {
						if (Array.isArray(value)) {
							const valueLabels = options
								.filter((option) => {
									return option.value && value.includes(option.value);
								})
								.map((option) => t(option.label) as string);
							return valueLabels.join(', ');
						} else {
							const returnValue = options.find((option) => {
								return option.value === value;
							})?.label;
							return t(returnValue as string);
						}
					}
				}}
				placeholder={placeholder}
				onChange={(event) => onSelect && onSelect(event.target.value)}
				sx={{
					'position': 'relative',
					'height': '60px',
					'margin': '0',
					'border': `1px solid ${errorText ? ColorPalette.violetRed : ColorPalette.periwinkle}`,
					'borderRadius': '10px',

					'color': value ? ColorPalette.astronaut : ColorPalette.baliHai,
					'fontWeight': 600,
					'fontSize': '14px',
					'lineHeight': '18px',

					'&:placeholder': {
						color: ColorPalette.baliHai,
					},
					'& .MuiInputBase-input': {
						display: 'flex',
						alignItems: 'center',
						height: '100%',
						padding: '20px 16px',
					},
					'& .MuiInputBase-input:focus': {
						backgroundColor: 'transparent',
					},
					'&:before, &:after': {
						display: 'none',
					},
					'label + &': {
						marginTop: '18px',
					},
				}}
			>
				{options.map((option) => (
					<MenuItem key={String(option.value)} value={option.value ?? ''}>
						{option.customLabel ? option.customLabel : t(option.label)}
					</MenuItem>
				))}
			</MUISelect>
			{errorText && <InputError text={t(errorText) as string} />}
		</FormControl>
	);
};
