import { FC, useMemo } from 'react';
import { Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Paper } from 'shared/ui/Paper';
import { SheetTable } from 'shared/ui/SheetTable';
import { ColorPalette } from 'shared/consts/colorPalette';
import { Button } from 'shared/ui/Button';
import { useAuthStore } from 'processes/auth/model/state';

const PartnersCsvOptions: FC = () => {
	const { i18n, t } = useTranslation();
	const { company } = useAuthStore();

	const pathToDownload = useMemo(
		() =>
			company?.metadata?.isSichim
				? `/files/Anagrafica_sichim_${i18n.language}.csv`
				: `/files/Anagrafica_${i18n.language}.csv`,
		[i18n, company],
	);

	const sheetPreviewArr = useMemo(() => {
		let defaultArr = [
			{ cellContent: t('partners.csv_cells.company_name') },
			{ cellContent: t('partners.csv_cells.vat_number') },
			{ cellContent: t('partners.csv_cells.client_supplier') },
			{ cellContent: t('partners.csv_cells.address') },
			{ cellContent: t('partners.csv_cells.erpCode') },
			{ cellContent: t('partners.csv_cells.replacePartnerInfo') },
			{ cellContent: t('partners.csv_cells.replaceCompanyInfo') },
			{ cellContent: t('partners.csv_cells.assertionSet') },
			{ cellContent: t('partners.csv_cells.modelName') },
		];

		if (company?.metadata?.isSichim) {
			defaultArr = [
				...defaultArr,
				{ cellContent: t('partners.csv_cells.vat_code') },
				{ cellContent: t('partners.csv_cells.address_code') },
			];
		}
		return defaultArr;
	}, [company]);

	return (
		<Paper
			sx={{
				width: '100%',
				padding: '25px 20px',
				display: 'flex',
				flexDirection: 'column',
				gap: '15px',
			}}
		>
			<Typography variant={'h5'} color={ColorPalette.astronaut}>
				{t('sync_settings.csv_example.partner_title')}
			</Typography>
			<Typography variant={'body2'} color={ColorPalette.baliHai}>
				{t('sync_settings.csv_example.partner_description')}
			</Typography>

			<SheetTable cells={sheetPreviewArr} />

			<Button
				sx={{
					alignSelf: 'flex-end',
					width: 'auto!important',
				}}
			>
				<Link
					sx={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
					underline="none"
					color={ColorPalette.white}
					href={pathToDownload}
					target="_blank"
					download
				>
					{t('sync_settings.csv_example.partner_download_btn')}
				</Link>
			</Button>
		</Paper>
	);
};

export default PartnersCsvOptions;
