import { ExtensionsType } from 'entities/Extensions/types';

export enum FeaturesEnum {
	NEURAL_MODEL = 'neural-models',
	RESELLER = 'reseller',
}

export enum DefaultExportType {
	JSON = 'JSON',
	XLSX = 'XLSX',
	CSV = 'CSV',
	XML = 'XML',
}

export enum DefaultExportDesination {
	DIRECT = 'DIRECT',
	FTP = 'FTP',
}

export type AdditionalFeatureType = {
	feature: FeaturesEnum;
	isEnabled: boolean;
	neuralModelsLimit?: number;
};

export type DefaultExport = {
	defaultType: DefaultExportType;
	defaultDestination: DefaultExportDesination;
};

export type Company = {
	_id: string;
	userId: string;
	name: string;
	address: {
		country: string;
		city: string;
		street: string;
		houseNumber: string;
		postalCode: string;
	};
	taxInfo: {
		vatNumber: string;
		sdiCode: string;
	};
	additionalFeatures: AdditionalFeatureType[];
	extensions: ExtensionsType[];
	defaultExport: DefaultExport;
	autoConnectPartners: boolean;
	createdAt: string;
	updatedAt: string;
	metadata?: {
		isSichim: boolean;
	};
};

export type CompanyForRequest = {
	name: Company['name'];
	country: Company['address']['country'];
	city: Company['address']['city'];
	street: Company['address']['street'];
	houseNumber: Company['address']['houseNumber'];
	postalCode: Company['address']['postalCode'];
	vatNumber: Company['taxInfo']['vatNumber'];
	sdiCode: Company['taxInfo']['sdiCode'];
};
